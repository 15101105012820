import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './EditionSlider.css';
import Modal from 'react-modal';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


const EditionSlider = ({ selectedSaga, onEditionSelected }) => {
    const [editions, setEditions] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [sets, setSets] = useState([]);
    const [selectedSetEditions, setSelectedSetEditions] = useState([]);

    const handleViewMore = () => {
        axios.get(`${API_BASE_URL}api/sets?sagaId=${selectedSaga.id}`)
            .then(response => {
                console.log("Respuesta del servidor sobre Sets:", response.data);
                setSets(response.data);
                setIsModalOpen(true);
            })
            .catch(error => {
                console.error("Error al obtener los sets:", error);
            });
    };
    

    const handleSetClick = (set) => {
        axios.get(`${API_BASE_URL}api/editionsBySet?setId=${set.id}`)
            .then(response => {
                console.log("Respuesta del servidor sobre Ediciones por Set:", response.data);
                setSelectedSetEditions(response.data);
            })
            .catch(error => {
                console.error("Error al obtener las ediciones del set:", error);
            });
    };

    const handleBackClick = () => {
        setSelectedSetEditions([]); // Limpia el estado de selectedSetEditions
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSetEditions([]); // Limpia el estado de selectedSetEditions al cerrar el modal
    };

    useEffect(() => {
        if (selectedSaga) {
            axios.get(`${API_BASE_URL}api/editions?sagaId=${selectedSaga.id}`)
                .then(response => {
                    console.log("Respuesta del servidor sobre Ediciones:", response.data);
                    setEditions(response.data);
                })
                .catch(error => {
                    console.error("Error al obtener las ediciones:", error);
                });
        }
    }, [selectedSaga]);

    const handleEditionClick = (selectedEdition) => {
        if (onEditionSelected) {
            onEditionSelected(selectedEdition);
        }
        handleCloseModal(); 
    };

    return (
        <>
            <div className="edition-slider">
                {editions.slice(0, 10).map(edition => (
                    <div key={edition.id} className="edition-item" onClick={() => handleEditionClick(edition)}>
                        <img src={edition.logo} alt={edition.nombre} />
                    </div>
                ))}
                {editions.length > 10 && <button onClick={handleViewMore} className='vermas-button'>Ver más</button>}
            </div>
            <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal}>
            <button className="close-button" onClick={handleCloseModal}><i class="fa-regular fa-circle-xmark"></i>
            </button>
                {selectedSetEditions.length > 0 && (
                    <button className="back-button" onClick={handleBackClick}>Volver atrás</button>
                )}
                <div className="modal-grid">
                    {!selectedSetEditions.length ? (
                        sets.map(set => (
                            <div key={set.id} className="modal-button" onClick={() => handleSetClick(set)}>
                                <img src={set.logo} alt={set.nombre} />
                            </div>
                        ))
                    ) : (
                        selectedSetEditions.map(edition => (
                            <div key={edition.id} className="modal-button" onClick={() => handleEditionClick(edition)}>
                                <img src={edition.logo} alt={edition.nombre} />
                            </div>
                        ))
                    )}
                </div>
                {selectedSetEditions.length > 0 && (
                    <button className="back-button" onClick={handleBackClick}>Volver atrás</button>
                )}
            </Modal>
        </>
    );
}

export default EditionSlider;
