// CardPlaceholder.js
import React from 'react';
import './CardPlaceHolder.css';

const CardPlaceholder = () => {
  return (
    <div className="card-placeholder">
      <div className="card-image-placeholder"></div>
      <div className="card-info-placeholder">
        <div className="card-number-placeholder"></div>
        <div className="card-name-placeholder"></div>
      </div>
    </div>
  );
};

export default CardPlaceholder;
