import React, { useState, useEffect } from 'react'; // Añade useEffect
import './Exchange.css';
import SagaSelector from '../SagaSelector/SagaSelector';
import logo from '../../assets/images/logo.webp';
import ExchangeTable from './ExchangeTable/ExchangeTable';
import ExchangeModal from './ExchangeModal'; // Asegúrate de que la ruta sea correcta


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Exchange() {
    const [selectedSaga, setSelectedSaga] = useState([]);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const [mostSearched, setMostSearched] = useState([]);
    const [isExchangeModalOpen, setIsExchangeModalOpen] = useState(false);
    const [selectedCardForExchange, setSelectedCardForExchange] = useState(null);
    

    const handleSelectCardForExchange = (card) => {
        setSelectedCardForExchange(card);
        setIsExchangeModalOpen(true);
    };
    

    useEffect(() => {
        // Comprobar si hay una saga en el localStorage
        const lastSaga = localStorage.getItem('lastSelectedSaga');
        
        if (lastSaga) {
            // Si hay una saga en el localStorage, la configura como la saga seleccionada
            const currentSaga = JSON.parse(lastSaga);
            setSelectedSaga(currentSaga);
            
            // Cargar las cartas más buscadas de esa saga
            loadMostSearchedCards(currentSaga);
        } else {
            // Si no hay ninguna saga en el localStorage, configura la saga predeterminada
            const defaultSaga = { id: 1, name: 'Pokemon' };
            setSelectedSaga(defaultSaga);
            
            // Cargar las cartas más buscadas de la saga predeterminada
            loadMostSearchedCards(defaultSaga);
        }
    }, []);  // El array vacío significa que este useEffect solo se ejecutará una vez, al cargar el componente
    
    useEffect(() => {
        if (selectedCardForExchange) {
          setIsExchangeModalOpen(true);
        }
      }, [selectedCardForExchange]);
      

    const handleLogout = () => {
        // Limpiar cualquier dato de sesión almacenado en localStorage
        localStorage.removeItem('userId');
        localStorage.removeItem('lastSelectedEdition');
        localStorage.removeItem('lastSelectedSaga');
        localStorage.removeItem('userToken');
        
        // Redirigir al usuario a la página de inicio de sesión
        window.location.href = '/login';
    };

    const loadMostSearchedCards = (currentSaga = selectedSaga) => {        
        //let currentSaga = selectedSaga;

        // Si no hay una saga seleccionada
        if (!currentSaga.id) {
            const lastSaga = localStorage.getItem('lastSelectedSaga');
            
            // Si existe una saga previamente seleccionada en localStorage, úsala
            if (lastSaga) {
                currentSaga = JSON.parse(lastSaga);
                setSelectedSaga(currentSaga);
            } else {
                // Si no, establece la saga por defecto a "Pokemon" con ID 1
                currentSaga = { id: 1, name: 'Pokemon' };
                setSelectedSaga(currentSaga);
            }
        }
        
        // Realiza la llamada al endpoint /api/mostwanted
        fetch(`${API_BASE_URL}api/mostwanted?sagaId=${currentSaga.id}`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('No se pudo obtener la respuesta del servidor.');
                }
                return response.json();
            })
            .then(data => setMostSearched(data))
            .catch(error => console.error('Hubo un error al obtener las cartas más buscadas:', error));
    };

    useEffect(() => {
        if (selectedSaga && selectedSaga.id) {
            localStorage.setItem('lastSelectedSaga', JSON.stringify(selectedSaga));
        }
    }, [selectedSaga]);
    


    return (
        <div className="exchange-container">

            <div className="sagas-logos">
                <SagaSelector 
                    selectedSaga={selectedSaga}
                    onSagaSelect={(saga) => {
                        setSelectedSaga(saga);
                        {console.log("Saga recibida:", saga);}
                        loadMostSearchedCards(saga);
                    }} 
                />
            </div>
            <header className="exchange-header">
                <a href='/Home'><img src={logo} alt="Logo" className="exchange-logo" /></a>
                <nav className="exchange-menu">
                    <a href="/Home">Colecciones</a>
                    <a href="/Exchange" className="active">Intercambios</a>
                    <a href="#">Market</a>
                </nav>
                <button className="account-button" onClick={() => setAccountMenuOpen(!accountMenuOpen)}>Mi cuenta</button>
                    {accountMenuOpen && (
                        <div className="account-menu-dropdown">
                            <button onClick={handleLogout}>Cerrar sesión</button>
                        </div>
                    )}
            </header>
            
            

            {/* Mostrar las cartas más buscadas */}
            <div className="most-searched-section">
                <h2>Los más buscados</h2>
                <div className="most-searched-cards">
                    {mostSearched.map(card => (
                        <div key={card.id} className="card">
                            <img src={card.imagen} alt={card.nombre} />
                            <p>{card.nombre}</p>
                            <img src={card.edicionLogo} alt="Logo de edición" className="exchange-edition-logo" />
                        </div>
                    ))}
                </div>
            </div>
            {/* Aquí debes agregar el cuerpo principal de Exchange y cualquier otro componente o lógica que necesites. */}
            <ExchangeTable selectedSaga={selectedSaga} onSelectCardForExchange={handleSelectCardForExchange}/>
            {isExchangeModalOpen && (
            <ExchangeModal
                card={selectedCardForExchange}
                onClose={() => setIsExchangeModalOpen(false)}
            />
        )}
        </div>
    );
}

export default Exchange;
