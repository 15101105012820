import React, { useState, useEffect } from 'react';
import './ExchangeTable.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const CARDS_PER_PAGE = 30;

function ExchangeTable({ selectedSaga, onSelectCardForExchange }) {
    const [exchangeCards, setExchangeCards] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [filterEdition, setFilterEdition] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [filterExchangeMethod, setFilterExchangeMethod] = useState('');
    const [filterRegion, setFilterRegion] = useState('');


    // Función para cargar las cartas
    const loadExchangeCards = async () => {
        try {
            const response = await fetch(`${API_BASE_URL}api/availableForExchange?sagaId=${selectedSaga.id}`);
            const data = await response.json();

            // Actualizar el estado con las cartas obtenidas
            setExchangeCards(data);
        } catch (error) {
            console.error("Error al cargar las cartas para intercambio:", error);
        }
    };

    // Utilizamos el hook useEffect para cargar las cartas apenas se monta el componente
    useEffect(() => {
        if (selectedSaga && selectedSaga.id) {
            loadExchangeCards();
        }
    }, [selectedSaga]);

    const handleExchangeMethodChange = (e) => {
        setFilterExchangeMethod(e.target.value);
        setCurrentPage(1); // reset to first page
    };

    const handleRegionChange = (e) => {
        setFilterRegion(e.target.value);
        setCurrentPage(1); // reset to first page
    }
    
    const handleEditionChange = (e) => {
        setFilterEdition(e.target.value);
        setCurrentPage(1); // reset to first page
    };

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // reset to first page
    };

    const clearSearch = () => {
        setSearchQuery('');
        setCurrentPage(1); // Opcional: regresar a la primera página después de limpiar la búsqueda
    };

    // En ExchangeTable.js

    const handleInitiateExchange = (card) => {
        // Creamos un objeto `owner` con la información del usuario dueño de la carta
        const owner = {
            username: card.userName, // Asegúrate de que coincidan los nombres de propiedad
            rating: card.userRating, // Suponiendo que tienes una propiedad `userRating`
            region: card.regionName,
            exchangeMethod: card.metodoIntercambio
        };

        // Luego, pasamos tanto la carta seleccionada como la información del dueño al método `onSelectCardForExchange`
        onSelectCardForExchange(card, owner);
    };

    const filteredCards = exchangeCards
    .filter(card => (filterEdition ? card.edicionNombre === filterEdition : true))
    .filter(card => card.cardName.toLowerCase().includes(searchQuery.toLowerCase()))
    .filter(card => (filterExchangeMethod ? card.metodoIntercambio === filterExchangeMethod : true))
    .filter(card => (filterRegion ? card.regionName === filterRegion : true));

    const displayedCards = filteredCards.slice((currentPage - 1) * CARDS_PER_PAGE, currentPage * CARDS_PER_PAGE);
    const editions = [...new Set(exchangeCards.map(card => card.edicionNombre))];
    const exchangeMethods = ["Presencial", "Envío", "Ambos"];
    const regions = [...new Set(exchangeCards.map(card => card.regionName))];


    const totalPages = Math.ceil(filteredCards.length / CARDS_PER_PAGE);

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    return (
        <div className="exchange-list-section">
            <h2>Cartas disponibles para intercambio</h2>
            <div className="filters-container">
                <select value={filterEdition} onChange={handleEditionChange}>
                    <option value="">Todas las ediciones</option>
                    {editions.map(edition => <option key={edition} value={edition}>{edition}</option>)}
                </select>
                <select value={filterExchangeMethod} onChange={handleExchangeMethodChange}>
                    <option value="">Todos los métodos</option>
                    {exchangeMethods.map(method => <option key={method} value={method}>{method}</option>)}
                </select>
                <select value={filterRegion} onChange={handleRegionChange}>
                    <option value="">Todas las regiones</option>
                    {regions.map(region => <option key={region} value={region}>{region}</option>)}
                </select>
                <div className="search-container">
                    <input
                        type="text"
                        placeholder="Buscar carta..."
                        value={searchQuery}
                        onChange={handleSearchChange}
                        className="search-input-exchange"
                    />
                    {searchQuery && (
                        <button onClick={clearSearch} className="clear-search-button">
                            <i className="fa-solid fa-xmark"></i> {/* Asegúrate de tener FontAwesome incluido o reemplaza con tu ícono */}
                        </button>
                    )}
                </div>            </div>
            <div className="grid-container">
                {displayedCards.map(card => (
                    <div key={card.id} className="grid-item">
                        <div className="card-image-container">
                            <img src={card.cardImage} alt={card.cardName} className="grid-card-image"/>
                        </div>
                        <div className="card-info-container">
                            <div className="card-edition">
                                <img src={card.edicionLogo} alt={card.edicionNombre} className="grid-edition-logo"/>
                                <span>{card.edicionNombre}</span>
                            </div>
                            <h3 className="card-name">{card.cardName}</h3>
                            <span className="card-number">#{card.numeroCarta}</span>
                            <div className="user-info">
                                <span className="username">{card.userName}</span>
                                <span className="region">{card.regionName}</span>
                            </div>
                            
                            <button className='button-view' onClick={() => handleInitiateExchange(card)}>
                                Ver <i className="fa-regular fa-eye"></i>
                            </button>
                            <div className="date-info">
                                {new Date(card.fechaIntercambio).toLocaleDateString()}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            {filteredCards.length > CARDS_PER_PAGE && (
                <div className="pagination">
                    {filteredCards.length > CARDS_PER_PAGE && (
                        <div className="pagination">
                            <button disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)}>Anterior</button>
                            <span>{currentPage} / {totalPages}</span>
                            <button disabled={currentPage === totalPages} onClick={() => handlePageChange(currentPage + 1)}>Siguiente</button>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
 }


export default ExchangeTable;
