import {React, useState} from 'react';
import axios from 'axios';
import './SearchResultsTable.css';
import ViewCard from '../../ViewCard/ViewCard';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const handleSearch = async (selectedSaga, searchTerm, setSearchResults) => {
    try {
        const userId = localStorage.getItem('userId');
        const response = await axios.get(`${API_BASE_URL}api/searchcards`, {
            params: {
                sagaId: selectedSaga.id,
                term: searchTerm,
                userId: userId // Añadimos el userId aquí
            }
        });
        setSearchResults(response.data);
    } catch (error) {
        console.error("Error al buscar las cartas:", error);
    }
};

function SearchResultsTable({ selectedSaga, searchTerm, setSearchResults, searchResults, cards, userCards,setUserEditionCards, setWishlistCards }) {
    const userId = localStorage.getItem('userId'); // Lo movemos aquí para no repetirlo en cada función
    const [viewCardId, setViewCardId] = useState(null);

    const handleAddOrRemoveFromCollection = async (cardId, inCollection) => {
        try {
            if (inCollection) {
                await axios.delete(`${API_BASE_URL}api/removecardfromcollection`, {
                    data: { userId, cardId }
                });
            } else {
                await axios.post(`${API_BASE_URL}api/addcardtocollection`, {
                    userId, cardId
                });
            }
            handleSearch(selectedSaga, searchTerm, setSearchResults); // Refrescar los resultados después de la acción
        } catch (error) {
            console.error("Error al modificar la colección:", error);
        }
    };


    const handleAddToCollection = async (cardId) => {
        try {
            const userId = localStorage.getItem('userId'); // Suponiendo que el ID del usuario se almacena en localStorage
            const response = await axios.post(`${API_BASE_URL}api/addToCollection`, {
                userId: userId,
                cardId: cardId
            });
            console.log(response.data);
        } catch (error) {
            console.error("Error al agregar carta a la colección:", error);
        }
    };

    const handleTrade = async (cardId, paraIntercambio) => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.put(`${API_BASE_URL}api/markForTrade`, {
                userId: userId,
                cardId: cardId,
                paraIntercambio: paraIntercambio ? 0 : 1
            });
            console.log(response.data);
            
            // Refrescar los resultados después de la acción
            handleSearch(selectedSaga, searchTerm, setSearchResults);
        } catch (error) {
            console.error("Error al marcar carta para intercambio:", error);
        }
    };

    const handleLookingFor = async (cardId, busco) => {
        try {
            const userId = localStorage.getItem('userId');
            const response = await axios.post(`${API_BASE_URL}api/markAsLookingFor`, {
                userId: userId,
                cardId: cardId,
                busco: busco ? 0 : 1 // Cambia el estado actual de busco
            });
            console.log(response.data);
            
            // Refrescar los resultados después de la acción
            handleSearch(selectedSaga, searchTerm, setSearchResults);
        } catch (error) {
            console.error("Error al marcar carta como buscada:", error);
        }
    };

    return (
        <div>
            {/*viewCardId && <ViewCard cardId={viewCardId} onClose={() => setViewCardId(null)} />*/}
            {viewCardId && <ViewCard 
                cardId={viewCardId} 
                onClose={() => setViewCardId(null)} 
                cards={cards} 
                userCards={userCards} 
                setUserEditionCards={setUserEditionCards} 
                setWishlistCards={setWishlistCards} 
                showButtons={false} 
            />}

            {searchResults.length > 0 && (
                <table className="search-results-table">
                    <thead>
                        <tr>
                            <th>EDICIÓN</th>
                            <th>CARTA</th>
                            <th>NÚMERO</th>
                            <th>NOMBRE</th>
                            <th>EN COLECCIÓN</th>
                            <th>PARA INTERCAMBIO</th>
                            <th>LO BUSCO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchResults.map(card => (
                            <tr key={card.id}>
                                <td><img src={card.editionLogo} alt={card.editionName}/></td>
                                <td><img src={card.imagen} alt={card.name} onClick={() => setViewCardId(card.id)} /></td>
                                <td>{card.numeroCarta}</td>
                                <td>{card.nombre}</td>
                                <td>
                                    <button 
                                        className={card.enColeccion ? "btn-red" : "btn-green"}
                                        onClick={() => handleAddOrRemoveFromCollection(card.id, card.enColeccion)}
                                    >
                                        {card.enColeccion ? "Eliminar" : "Agregar"}
                                    </button>
                                </td>
                                <td>
                                    <button 
                                        className={card.paraIntercambio ? "btn-red" : "btn-green"}
                                        onClick={() => handleTrade(card.id, card.paraIntercambio)}
                                    >
                                        {card.paraIntercambio ? "Cancelar intercambio" : "Intercambio"}
                                    </button>
                                </td>
                                <td>
                                    <button 
                                        className={card.busco ? "btn-red" : "btn-green"}
                                        onClick={() => handleLookingFor(card.id, card.busco)}
                                    >
                                        {card.busco ? "Dejar de buscar" : "Busco"}
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );    
}

export default SearchResultsTable;