import React, { useState } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import './ForgotPassword.css';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ForgotPassword() {
    const navigate = useNavigate();
    const [emailValue, setEmailValue] = useState('');
    const [message, setMessage] = useState('');

    const handleForgotPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}api/forgot-password`, {
                email: emailValue
            });
            setMessage(response.data.message);
            // Redirige al usuario a la página de restablecimiento de contraseña
            navigate('/reset-password');
        } catch (error) {
            setMessage('Error al enviar el código. Por favor, intenta nuevamente.');
        }
    };

    return (
        <div className="forgot-password-container">
            <h2>Recuperar Contraseña</h2>
            <form onSubmit={handleForgotPassword}>
                <label htmlFor="email">Ingresa tu e-mail:</label>
                <input type="email" id="email" name="email" required 
                       value={emailValue} 
                       onChange={(e) => setEmailValue(e.target.value)} />
                
                <button type="submit">Enviar Código</button>
            </form>
            <p>{message}</p>
            <Link to="/login">Volver al inicio de sesión</Link>
        </div>
    );
    
}

export default ForgotPassword;
