import React, { useEffect, useState,Suspense  } from 'react';
import './CardGrid.css';
import axios from 'axios';
import ViewCard from '../../ViewCard/ViewCard';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import CardPlaceholder from '../../CardPlaceHolder';


const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const LazyImage = React.lazy(() => import('../../LazyImage'));

const CardGrid = ({ cards, userCards, setUserEditionCards, selectedEdition }) => {
    const [viewCardId, setViewCardId] = useState(null);
    console.log("Cartas del usuario:", userCards);
    const [wishlistCards, setWishlistCards] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        loadUserCards();
    }, [selectedEdition]);

    const loadUserCards = async () => {
        if (!selectedEdition) {
            setLoading(false);
            return;
        }
        setLoading(true); // Inicia la carga
        const userId = localStorage.getItem('userId');
        const editionId = selectedEdition.id;
        try {
            const response = await axios.get(`${API_BASE_URL}api/usercards`, {
                params: {
                    editionId,
                    userId
                }
            });
            const userCardsData = response.data;
            setUserEditionCards(userCardsData);
            // Actualiza el estado de wishlistCards con los IDs de las cartas que están en la lista de deseos
            const wishlistCardIds = userCardsData.filter(card => card.inWishlist).map(card => card.id);
            setWishlistCards(wishlistCardIds);
            console.log("Datos de la API:", userCardsData);
        } catch (error) {
            console.error("Error al cargar las cartas del usuario:", error);
        } finally {
            setLoading(false); // Finaliza la carga incluso si hay un error
        }
    }

    const handleAddToCollection = async (cardId) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.post(`${API_BASE_URL}api/addcardtocollection`, {
                userId,
                cardId
            });
            console.log(response.data.message);

            const addedCard = cards.find(card => card.id === cardId);
            setUserEditionCards(prevUserCards => [...prevUserCards, addedCard]);

            // Mostrar la notificación
        const cardName = addedCard.nombre;
        const cardNumber = addedCard.numeroCarta;
        toast.success(`Se ha agregado la carta ${cardNumber} - ${cardName} a tu colección.`, {
            position: "bottom-center",
            autoClose:1500,
            theme: "dark"
        });

        } catch (error) {
            console.error("Error al agregar la carta:", error);
        }
    };

    const handleRemoveFromCollection = async (cardId) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.delete(`${API_BASE_URL}api/removecardfromcollection`, {
                data: { userId, cardId }
            });
            console.log(response.data.message);

            setUserEditionCards(prevUserCards => prevUserCards.filter(card => card.id !== cardId));

           toast.success(`Se ha eliminado la carta de tu colección.`, {
            position: "bottom-center",
            theme: "dark",
            autoClose:1500
        });

        } catch (error) {
            console.error("Error al eliminar la carta:", error);
        }
    };

    const handleToggleTrade = async (cardId, currentTradeStatus) => {
        const userId = localStorage.getItem('userId');
        const newTradeStatus = currentTradeStatus === 1 ? 0 : 1;
    
        try {
            const response = await axios.put(`${API_BASE_URL}api/toggletradestatus`, {
                userId,
                cardId,
                paraIntercambio: newTradeStatus
            });
    
            console.log(response.data.message);
            const updatedUserCards = userCards.map(userCard => 
                userCard.id === cardId ? { ...userCard, paraIntercambio: newTradeStatus } : userCard
            );
            setUserEditionCards(updatedUserCards);
    
            // Obtener el nombre y número de la carta
            const card = cards.find(c => c.id === cardId);
            const cardName = card.nombre;
            const cardNumber = card.numeroCarta;
    
            // Mostrar la notificación de toast dependiendo del estado de intercambio
            if (newTradeStatus === 1) {
                toast.success(`Se ha marcado la carta ${cardNumber} - ${cardName} para intercambio.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 1500
                });
            } else {
                toast.success(`Se ha sacado la carta ${cardNumber} - ${cardName} del intercambio.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 1500
                });
            }
    
        } catch (error) {
            console.error("Error al actualizar el estado de intercambio:", error);
        }
    };
    

    const handleAddToWishlist = async (cardId) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.post(`${API_BASE_URL}api/addcardtowishlist`, {
                userId,
                cardId
            });
            console.log(response.data.message);
    
            // Actualizar el estado de wishlistCards
            setWishlistCards(prevWishlist => [...prevWishlist, cardId]);
    
            // Actualizar el estado de userCards para reflejar que la carta ahora está en "busco" (wishlist)
            const addedWishlistCard = cards.find(card => card.id === cardId);
            setUserEditionCards(prevUserCards => [...prevUserCards, { ...addedWishlistCard, inWishlist: true }]);

            const cardName = addedWishlistCard.nombre;
            const cardNumber = addedWishlistCard.numeroCarta;
            toast.success(`Se ha marcado la carta ${cardNumber} - ${cardName} como buscado.`, {
                position: "bottom-center",
                theme: "dark",
                autoClose: 2000
            });

        } catch (error) {
            console.error("Error al agregar la carta a la lista de deseos:", error);
        }
    };
    
    const handleToggleWishlist = async (cardId) => {
        const userId = localStorage.getItem('userId');
        const userCard = userCards.find(uc => uc.id === cardId);
        const isOnWishlist = userCard && userCard.inWishlist;
    
        try {
            let response;
            const card = cards.find(c => c.id === cardId);
            const cardName = card.nombre;
            const cardNumber = card.numeroCarta;
    
            if (isOnWishlist) {
                // Si la carta ya está en la lista de deseos, eliminarla
                response = await axios.delete(`${API_BASE_URL}api/removecardfromwishlist`, {
                    data: { userId, cardId }
                });
                setWishlistCards(prevWishlist => prevWishlist.filter(id => id !== cardId));
    
                // Actualizar el estado de userCards para reflejar que la carta ya no está en "busco" (wishlist)
                setUserEditionCards(prevUserCards => prevUserCards.map(userCard => 
                    userCard.id === cardId ? { ...userCard, inWishlist: false } : userCard
                ));
    
                // Eliminar la carta de userCards si no está en la colección del usuario
                if (!userCard.enColeccion) {
                    setUserEditionCards(prevUserCards => prevUserCards.filter(uc => uc.id !== cardId));
                }
    
                // Mostrar la notificación de que se ha eliminado de la lista de deseos
                toast.success(`Se ha eliminado la carta ${cardNumber} - ${cardName} de tu lista de buscados.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 2000
                });
    
            } else {
                // Si la carta no está en la lista de deseos, agregarla
                response = await axios.post(`${API_BASE_URL}api/addcardtowishlist`, {
                    userId,
                    cardId
                });
                setWishlistCards(prevWishlist => [...prevWishlist, cardId]);
    
                // Actualizar el estado de userCards para reflejar que la carta ahora está en "busco" (wishlist)
                const addedWishlistCard = cards.find(card => card.id === cardId);
                setUserEditionCards(prevUserCards => [...prevUserCards, { ...addedWishlistCard, inWishlist: true }]);
    
                // Mostrar la notificación de que se ha agregado a la lista de deseos
                toast.success(`Se ha marcado la carta ${cardNumber} - ${cardName} como buscado.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 2000
                });
            }
            console.log(response.data.message);
            loadUserCards();
        } catch (error) {
            console.error("Error al actualizar la lista de deseos:", error);
        }
    };
    
    
    if (!cards.length) {
        return <div>Seleccione una edición...</div>;
    }

    return (
        <>
          <ToastContainer />
          <div className="card-grid">
            {loading ? (
              // Muestra los placeholders mientras las cartas están cargando
              Array.from({ length: 27 }, (_, index) => (
                <CardPlaceholder key={index} />
              ))
            ) : (
              // Una vez que las cartas han cargado, muestra las cartas reales
              
              cards.map(card => {
                const userCard = userCards.find(userCard => userCard.id === card.id);
                const userHasCard = !!userCard && !userCard.inWishlist;
                const isOnWishlist = !!userCard && userCard.inWishlist;
                const isForTrade = userHasCard && userCard.paraIntercambio === 1;
      
                return (
                  <div key={card.id} className="card-item">
                    <Suspense fallback={<CardPlaceholder />}>
                    <LazyImage 
                      src={card.imagen} 
                      alt={card.nombre} 
                      className={userHasCard ? 'card-color' : 'card-bw'}
                      onClick={() => setViewCardId(card.id)}
                    />
                    </Suspense>
                    <div className={`card-label ${userHasCard ? '' : 'missing-card'}`}>
                      <span>{card.numeroCarta}</span>
                      <span>{card.nombre}</span>
                    </div>
                    <div className="card-buttons">
                      {userHasCard ? 
                        <button className="remove-btn" title='Quitar de mi colección' onClick={() => handleRemoveFromCollection(card.id)}><i className="fas fa-times"></i></button> :
                        <button className="add-btn" title='Agregar a mi colección' onClick={() => handleAddToCollection(card.id)}><i className="fas fa-plus"></i></button>
                      }
                    </div>
                    {userHasCard && 
                      <button 
                        className={`trade-btn ${isForTrade ? 'active-trade' : ''}`}
                        onClick={() => handleToggleTrade(card.id, isForTrade ? 1 : 0)}
                        title={isForTrade ? "Quitar de intercambio" : "Agregar a intercambio"}
                      >
                        <i className={`fas fa-${isForTrade ? 'rotate-left': 'arrows-rotate'}`}></i>
                      </button>
                    }
                    { !userHasCard && 
                      <button 
                        className="search-btn"
                        onClick={() => handleToggleWishlist(card.id)}
                        title={isOnWishlist ? "Dejar de buscar":"Buscar carta"}
                      >
                        <i className={`fa-${isOnWishlist ? 'regular fa-hand' : 'brands fa-searchengin'}`}></i>
                      </button>
                    }
                  </div>
                );
              })
            )}
          </div>
          {viewCardId && <ViewCard cardId={viewCardId} onClose={() => setViewCardId(null)} cards={cards} userCards={userCards} setUserEditionCards={setUserEditionCards} setWishlistCards={setWishlistCards} showButtons={true} />}
        </>
      );
}

export default CardGrid;
