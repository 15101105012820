// En el archivo ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children }) => {
    const isAuthenticated = localStorage.getItem('userToken'); // Asume que guardas el token JWT bajo la clave 'token'

    return isAuthenticated ? children : <Navigate to="/login" />;
};

export default ProtectedRoute;
