import React, { useState } from 'react';
import './Login.css';
import logo from '../../assets/images/logo.webp';
import axios from 'axios'; //para hacer solicitudes HTTP
import { useNavigate } from 'react-router-dom';//para manejar redireccionamiento
import { Link } from 'react-router-dom';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

if (localStorage.userToken) {
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.userToken;
} else {
    delete axios.defaults.headers.common['Authorization'];
}

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response.status === 401) { // Si el servidor devuelve un error 401 (no autorizado)
            localStorage.removeItem('userToken'); // Eliminar el token del localStorage
            window.location = '/login'; // Redirigir al usuario a la página de inicio de sesión
        }
        return Promise.reject(error);
    }
);


function Login() {
    const navigate = useNavigate();
    const [emailValue, setEmailValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [errorMessage, setErrorMessage] = useState('');


    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}api/login`, {
                email: emailValue,
                password: passwordValue
            });
            
            if (response.data.token) {
                localStorage.setItem('userToken', response.data.token);
                localStorage.setItem('userId', response.data.userId);
                navigate('/home');
            } else if (response.data.message) {
                setErrorMessage(response.data.message);
            } else {
                setErrorMessage('Error al iniciar sesión. Por favor, intenta nuevamente.');
            }
        } catch (error) {
            setErrorMessage('Usuario y/o contraseña incorrecto.');
        }
    };
    

    return (
        <div className="login-container">
            <div className="login-logo-section">
            </div>
            <div className="login-form-section">
                <div className='login-logo'>
                <img src={logo} alt="Logo" className="login-logo-img" />
                </div>
                <h2 className="login-title">Log in</h2>
                <div className='formulario-login'>
                <form className="login-form" onSubmit={handleLogin}>
                    <label htmlFor="email">Ingresa tu e-mail:</label>
                    <input type="email" id="email" name="email" required 
                           value={emailValue} 
                           onChange={(e) => setEmailValue(e.target.value)} />
                    
                    <label htmlFor="password">Ingresa tu contraseña:</label>
                    <input type="password" id="password" name="password" required 
                           value={passwordValue} 
                           onChange={(e) => setPasswordValue(e.target.value)} />
                    
                    <button type="submit" onClick={handleLogin}>Iniciar Sesión</button>
                    <p className="error-message">{errorMessage}</p>
                    <p className="forgot-password"><Link to="/forgot-password" className="forgot-password">¿Olvidaste tu contraseña?</Link></p>
                    
                </form>
                </div>
                <div className="signup-section">
                    <p className="signup-text">¿No tienes una cuenta?</p>
                    <Link to="/register">
                    <button className="signup-button">Registrarse</button>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default Login;
