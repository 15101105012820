import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './Register.css';
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const Register = () => {
    const [step, setStep] = useState(1);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [instagram, setInstagram] = useState('');
    const [facebook, setFacebook] = useState('');
    const [region, setRegion] = useState('');
    const [city, setCity] = useState('');
    const [comuna, setComuna] = useState('');
    const [exchangeMethod, setExchangeMethod] = useState('');
    const [regions, setRegions] = useState([]);
    const [cities, setCities] = useState([]);
    const [communes, setCommunes] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [registrationSuccess, setRegistrationSuccess] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [generalError, setGeneralError] = useState('');
    const [exchangeMethodError, setExchangeMethodError] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [nameError, setNameError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [regionError, setRegionError] = useState('');
    const [cityError, setCityError] = useState('');
    const [comunaError, setComunaError] = useState('');
    const [usernameAvailable, setUsernameAvailable] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);
    const { executeRecaptcha } = useGoogleReCaptcha();

    useEffect(() => {
        // Obtener las regiones al cargar el componente
        axios.get(`${API_BASE_URL}api/regions`)
            .then(response => {
                setRegions(response.data);
            })
            .catch(error => {
                console.error("Error al obtener las regiones:", error);
            });
    }, []);

    const handleNext = async () => {

        if (!executeRecaptcha) {
            console.log('Execute recaptcha not yet available');
            return;
          }

        // Aquí ejecutarías el recaptcha cada vez que el usuario hace clic en 'Siguiente'
        const token = await executeRecaptcha('register');
        setCaptchaValue(token);
        // Validar que el email y la contraseña no estén vacíos y que el email sea válido, y también que recaptcha esté completo
        if (email === '' || password === '' || emailError !== '') {
            alert('Por favor, asegúrate de que todos los campos estén correctamente rellenados antes de avanzar.');
            return;
          }
        
        try {
            // Guarda el email y la contraseña de forma temporal en la base de datos
            await axios.post(`${API_BASE_URL}api/temporary_register`, { email, password });
            // Si la solicitud es exitosa, pasa al siguiente paso
            setStep(2);
        } catch (error) {
            if (error.response && error.response.status === 409) {
                setGeneralError(
                    <span>
                        {error.response.data.error} 
                        <a href="/login">Ir al login</a>
                    </span>
                );
            } else {
                console.error('Error al guardar el registro temporal:', error);
                alert('Error al guardar el registro temporal.');
            }
        }
    };
    
     
      const validateEmail = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailRegex.test(email)) {
            setEmailError('El formato del correo no es válido');
        } else {
            setEmailError('');
        }
    };
    
    const checkUsernameAvailability = async () => {
        if(username.trim() === '') {
            setUsernameError('');
            setUsernameAvailable(false);
            return;
        }
    
        try {
            const response = await axios.get(`${API_BASE_URL}api/check_username`, { params: { username } });
    
            if (response.data.exists) {
                setUsernameError('Ya existe ese nombre de usuario');
                setUsernameAvailable(false);
            } else {
                setUsernameError('');
                setUsernameAvailable(true);
            }
        } catch (error) {
            console.error('Error al verificar la disponibilidad del nombre de usuario:', error);
            setUsernameError('Error al verificar disponibilidad.');
            setUsernameAvailable(false);
        }
    };
      
    const onReCAPTCHAChange = async (token) => {
        try {
          const response = await fetch('/verify-recaptcha', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ token }),
          });
          const data = await response.json();
          if (data.success) {
            // El captcha es válido, puedes continuar con el proceso de tu formulario
          } else {
            // El captcha falló, maneja el error
          }
        } catch (error) {
          console.error('Error al verificar el reCAPTCHA', error);
        }
      };
      
      

    const handleRegionChange = (e) => {
        const regionId = e.target.value;
        setSelectedRegion(regionId);

        // Obtener las ciudades basadas en la región seleccionada
        axios.get(`${API_BASE_URL}api/cities?regionId=${regionId}`)
            .then(response => {
                setCities(response.data);
                setCommunes([]); // Limpiar las comunas
            })
            .catch(error => {
                console.error("Error al obtener las ciudades:", error);
            });
    };

    const handleCityChange = (e) => {
        const cityId = e.target.value;
        setSelectedCity(cityId);

        // Obtener las comunas basadas en la ciudad seleccionada
        axios.get(`${API_BASE_URL}api/communes?cityId=${cityId}`)
            .then(response => {
                setCommunes(response.data);
            })
            .catch(error => {
                console.error("Error al obtener las comunas:", error);
            });
    };

    const handleSubmit = async (e) => {
        e.preventDefault(); // Prevenir el comportamiento por defecto del formulario

        // Resetear errores
        setUsernameError('');
        setNameError('');
        setPhoneError('');
        setRegionError('');
        setCityError('');
        setComunaError('');
        setExchangeMethodError('');
    
        let hasError = false;
    
        if (!username) {
            setUsernameError('Este campo es obligatorio.');
            hasError = true;
        }
        if (!name) {
            setNameError('Este campo es obligatorio.');
            hasError = true;
        }
        if (!phone) {
            setPhoneError('Este campo es obligatorio.');
            hasError = true;
        }
        if (!selectedRegion) {
            setRegionError('Este campo es obligatorio.');
            hasError = true;
        }
        if (!selectedCity) {
            setCityError('Este campo es obligatorio.');
            hasError = true;
        }
        if (!comuna) {
            setComunaError('Este campo es obligatorio.');
            hasError = true;
        }
        if (!exchangeMethod) {
            setExchangeMethodError('Por favor, selecciona una preferencia de intercambio.');
            hasError = true;
        }
    
        if (hasError) {
            setGeneralError('Por favor, completa todos los campos obligatorios.');
            return;
        }

        // Validar la preferencia de intercambio
        if (!exchangeMethod) {
            setExchangeMethodError('Por favor, selecciona una preferencia de intercambio.');
            return; // Terminar la ejecución aquí si no se ha seleccionado una preferencia
        } else {
            setExchangeMethodError(''); // Limpiar el error si se seleccionó una preferencia
        }
        
        if (!username || !name || !phone || !selectedRegion || !selectedCity || !comuna || !exchangeMethod) {
            setGeneralError('Por favor, completa todos los campos obligatorios.');
            return;
        }
    
        try {
            const response = await axios.post(`${API_BASE_URL}api/register`, {
                email: email, 
                password: password,
                usuario: username,
                nombre: name,
                telefono: phone,
                instagram: instagram, // Este campo es opcional
                facebook: facebook,   // Este campo es opcional
                comunaId: comuna,
                metodoIntercambio: exchangeMethod,
                activo: 1
            });
        
            if (response.data.message) {
                setRegistrationSuccess(true);
                await axios.put(`${API_BASE_URL}api/update_temporary_register`, { email });
            }
        } catch (error) {
            setGeneralError('Error al registrar el usuario.');
        }
    };
    
    const handleInstagramFocus = () => {
        if (!instagram) {
            setInstagram('@');
        }
    };
    
    const handleFacebookFocus = () => {
        if (!facebook) {
            setFacebook('/');
        }
    };
    
    const handlePhoneFocus = () => {
        if (!phone) {
            setPhone('+56');
        }
    };

    return (
        <div className="register-container">
        {registrationSuccess ? (
            <div className="register-step1"> {/* Aquí se añade la clase */}
                <div className="register-success">
                    <h2>Registro exitoso</h2>
                    <p>Ya puedes iniciar sesión.</p>
                    <a href="/login">Ir al Login</a>
                </div>
            </div>
        ) : (
            <>
            {step === 1 ? (
                <div className="register-step1">
                    <h2 className='register-title'>CREAR NUEVA CUENTA</h2>
                    <form>
                    <div className="input-group">
                        <label htmlFor="email">E-mail:</label>
                        <input type="email" id="email" value={email} onChange={(e) => { setEmail(e.target.value); validateEmail(e.target.value); }} required />
                        {emailError && <span className="error-message">{emailError}</span>}
                    </div>
                        <div className="input-group">
                            <label htmlFor="password">Contraseña:</label>
                            <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
                        </div>
                        {generalError && <span className="error-message">{generalError}</span>}
                        <div className="input-group">
                        </div>
                        <button className="register-button register1-button" type="button" onClick={handleNext}>Siguiente</button>
                    </form>
                </div>
            ) : (
                <div className="register-step2">
                    <h2 className="register-title">Registro - Paso 2</h2>
                    <form onSubmit={handleSubmit}>
                        <label htmlFor="username">Usuario:</label>
                        <input 
                            type="text" 
                            id="username" 
                            placeholder="Usuario" 
                            value={username} 
                            onChange={(e) => setUsername(e.target.value)}
                            onBlur={checkUsernameAvailability}
                            style={{ borderColor: usernameError ? 'red' : usernameAvailable ? 'green' : '' }}
                        />

                        {usernameError && (
                            <>
                                <i className="fa-solid fa-circle-xmark" style={{ color: 'red' }}></i>
                                <span style={{ color: 'red' }}>{usernameError}</span>
                            </>
                        )}

                        {usernameAvailable && (
                            <>
                                <i className="fa-solid fa-circle-check" style={{ color: 'green' }}></i>
                                <span style={{ color: 'green' }}>Disponible</span>
                            </>
                        )}

                        {/*usernameError && <span className="error-message">{usernameError}</span>*/}

                        <label htmlFor="name">Nombre:</label>
                        <input type="text" id="name" placeholder="Nombre" value={name} onChange={(e) => setName(e.target.value)} style={{ borderColor: nameError ? 'red' : '' }} />
                        {nameError && <span className="error-message">{nameError}</span>}
                        <label htmlFor="phone">Teléfono:</label>
                        <input 
                            type="tel" 
                            id="phone" 
                            placeholder="Teléfono"
                            value={phone} 
                            onChange={(e) => setPhone(e.target.value)} 
                            onFocus={handlePhoneFocus}
                            style={{ borderColor: usernameError ? 'red' : '' }}
                        />
                         {phoneError && <span className="error-message">{phoneError}</span>}
                        <div className="contact-methods">
                            <div className="contact-methods-title">Métodos de contacto</div>
                            <label htmlFor="instagram">Instagram (opcional):</label>
                            <input 
                                type="text" 
                                id="instagram" 
                                placeholder="@nombreusuario"
                                value={instagram} 
                                onChange={(e) => setInstagram(e.target.value)} 
                                onFocus={handleInstagramFocus} 
                            />
                            <label htmlFor="facebook">Facebook (opcional):</label>
                            <input 
                                type="text" 
                                id="facebook" 
                                placeholder="/nombreusuario"
                                value={facebook} 
                                onChange={(e) => setFacebook(e.target.value)} 
                                onFocus={handleFacebookFocus}
                            />                        </div>

                        <label htmlFor="region">Región:</label>
                        <select id="region" value={selectedRegion} onChange={handleRegionChange} style={{ borderColor: usernameError ? 'red' : '' }}>
                            <option value="">Selecciona una región</option>
                            {regions.map(region => (
                                <option key={region.id} value={region.id}>{region.nombre}</option>
                            ))}
                        </select>
                        {regionError && <span className="error-message">{regionError}</span>}
                        <label htmlFor="city">Ciudad:</label>
                        <select id="city" value={selectedCity} onChange={handleCityChange}>
                            <option value="">Selecciona una ciudad</option>
                            {cities.map(city => (
                                <option key={city.id} value={city.id}>{city.nombre}</option>
                            ))}
                        </select>

                        <label htmlFor="comuna">Comuna:</label>
                        <select id="comuna" value={comuna} onChange={(e) => setComuna(e.target.value)}>
                            <option value="">Selecciona una comuna</option>
                            {communes.map(commune => (
                                <option key={commune.id} value={commune.id}>{commune.nombre}</option>
                            ))}
                        </select>

                        <label htmlFor="exchangeMethod">Preferencia de intercambio:</label>
                        <select id="exchangeMethod" value={exchangeMethod} onChange={(e) => setExchangeMethod(e.target.value)}>
                            <option value="">Selecciona una</option>
                            <option value="presencial">Presencial</option>
                            <option value="envio">Envío</option>
                            <option value="ambos">Ambos</option>
                        </select>
                        {exchangeMethodError && <span className="error-message">{exchangeMethodError}</span>}
                         <button className="register-button" type="submit">Finalizar Registro</button>
                    </form>
                    {generalError && <span className="error-message">{generalError}</span>}
                    </div>
                )}
            </>
        )}
    </div>
);
}

export default Register;
