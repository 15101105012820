import React, { useState, useEffect } from 'react';
import './ExchangeModal.css'; // Asegúrate de tener este archivo para estilos
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import { Navigation, Pagination, Scrollbar, A11y, Mousewheel } from 'swiper/modules';
import 'swiper/css'; // Importa los estilos de Swiper
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ExchangeModal = ({ card, onClose, owner }) => {
    // Estado para las cartas que el usuario logueado ofrece en el intercambio
    const [offeredCards, setOfferedCards] = useState([]);
    const [complementWithMoney, setComplementWithMoney] = useState(false);
    const [moneyAmount, setMoneyAmount] = useState('');
    const [ownerRating, setOwnerRating] = useState(null);
    const [userCards, setUserCards] = useState([]);
  
    // Estado para las cartas que el dueño de la carta está buscando
    const [ownerWishlist, setOwnerWishlist] = useState([]);
  
    // Estado para las cartas que el usuario logueado tiene y el dueño busca
    const [matchingCards, setMatchingCards] = useState([]);

    // Estado para el texto de búsqueda
    const [searchTerm, setSearchTerm] = useState('');
  
    useEffect(() => {

      // Cargar las cartas del usuario cuando el componente se monta
      const fetchUserCards = async () => {
        try {
          const userId = localStorage.getItem('userId'); // Asumiendo que el userId se guarda en localStorage
          const lastSelectedSaga = JSON.parse(localStorage.getItem('lastSelectedSaga'));
          if (userId) {
            const response = await fetch(`${API_BASE_URL}api/getUserExchangeCards?userId=${userId}&sagaId=${lastSelectedSaga.id}`);
            const data = await response.json();
            console.log("Cartas para intercambio de usuario logueado:",data);
            setUserCards(data);
          }
        } catch (error) {
          console.error("Error al obtener las cartas del usuario:", error);
        }
      };
  
      // Obtener la calificación del dueño de la carta
      const fetchOwnerRating = async () => {
        try {
          console.log("ID de usuario q buscaremos rating:", card.usuarioId);
          const response = await fetch(`${API_BASE_URL}api/userinfo?userId=${card.usuarioId}`);
          const results = await response.json();
          if (results && results.length > 0) {
            console.log("Información del usuario:", results[0]);
            setOwnerRating(results[0].rating); // Asegúrate de que el primer objeto 'results[0]' contenga la propiedad 'rating'
          } else {
            // Manejar el caso de que no se encuentren resultados o el array esté vacío
            console.log("No se encontraron resultados para el usuario con ID:", card.usuarioId);
          }
        } catch (error) {
          console.error("Error al obtener la puntuación del dueño:", error);
        }
      };
      fetchOwnerRating();
      fetchUserCards();
    }, [card]);
  
    useEffect(() => {
      // Función para cargar la wishlist del dueño de la carta y las cartas para intercambio
      const loadOwnerWishlistAndMatchingCards = async () => {
        if (!card) return;
        
        try {
          console.log("Obteniendo cartas buscadas por usuario");
          const lastSelectedSaga = JSON.parse(localStorage.getItem('lastSelectedSaga'));
          const wishlistResponse = await fetch(`${API_BASE_URL}api/getUserWishlistCards?userId=${card.usuarioId}&sagaId=${lastSelectedSaga.id}`);
          const wishlistData = await wishlistResponse.json();
          console.log("Cartas buscadas obtenidas:", wishlistData);
          setOwnerWishlist(wishlistData);
  
          // Filtra las cartas que el usuario tiene y el dueño busca
          const matching = userCards.filter(userCard =>
            wishlistData.some(wishlistCard => wishlistCard.cartaId === userCard.cartaId)
          );
          setMatchingCards(matching);
        } catch (error) {
          console.error("Error al cargar la lista de deseos del dueño y cartas coincidentes:", error);
        }
      };
  
      if (userCards.length > 0) {
        loadOwnerWishlistAndMatchingCards();
      }
    }, [userCards, card]);
  
    if (!card) return null; // No renderizar el modal si no hay carta seleccionada
  
    // Función para agregar una carta a la oferta
    const handleAddToOffer = (selectedCard) => {
        console.log('Agregando carta a la oferta:', selectedCard);
        console.log('ID de carta seleccionada:', selectedCard.id);
        console.log('ID carta seleccionada v2:', selectedCard.cartaId);
      // Evita duplicados
      if (offeredCards.some(offeredCard => offeredCard.cartaId === selectedCard.cartaId)) {
        return;
      }
      setOfferedCards(prevCards => {
        const updatedCards = [...prevCards, selectedCard];
        console.log('Estado actualizado de offeredCards:', updatedCards);
        return updatedCards;
      });
    };

    //Función para filtrar cartas según buscador
    const handleSearchChange = (event) => {
      setSearchTerm(event.target.value.toLowerCase());
    };
  
    // Filtra las cartas basadas en el término de búsqueda
    const filteredMatchingCards = matchingCards.filter((card) =>
      card.name.toLowerCase().includes(searchTerm)
    );
    const filteredUserCards = userCards.filter((card) =>
      card.name.toLowerCase().includes(searchTerm) && !filteredMatchingCards.includes(card)
    );

    // Función para agregar o quitar una carta de la oferta
    const toggleCardInOffer = (selectedCard) => {
      if (offeredCards.some(offeredCard => offeredCard.cartaId === selectedCard.cartaId)) {
        // Si la carta ya está en la oferta, la quitamos
        setOfferedCards(offeredCards.filter(offeredCard => offeredCard.cartaId !== selectedCard.cartaId));
      } else {
        // Si la carta no está en la oferta, la agregamos
        setOfferedCards([...offeredCards, selectedCard]);
      }
    };

  
    const handleSendOffer = async () => {
      const userId = localStorage.getItem('userId'); // Asumiendo que el userId se guarda en localStorage
      const offerData = {
        cartaOfrecidaId: offeredCards.map(card => card.id), // ID de las cartas que se ofrecen
        cartaSolicitadaId: card.id, // ID de la carta que se desea obtener
        estado: 'Iniciado', // Estado inicial de la oferta
        ofertasJSON: JSON.stringify({ cartas: offeredCards.map(card => card.id), monto: complementWithMoney ? moneyAmount : 0 }),
        montoOfrecido: complementWithMoney ? moneyAmount : 0,
        estadoOferta: 'Oferta', // Estado inicial de la oferta
        ofertanteId: userId // ID del usuario que hace la oferta
      };
    
      try {
        const response = await fetch(`${API_BASE_URL}api/sendOffer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(offerData)
        });
    
        if (!response.ok) {
          const errorBody = await response.text(); // O 'response.json()' si el servidor devuelve JSON
          console.error('Error en la respuesta:', errorBody);
          throw new Error('Hubo un problema al enviar la oferta: ' + errorBody);
        }        
        
        const result = await response.json();
        console.log('Oferta enviada con éxito:', result);
        onClose(); // Cierra el modal después de enviar la oferta
      } catch (error) {
        console.error('Error al enviar la oferta:', error);
      }
    };
    
  
    // Función para formatear el monto de dinero
    const formatMoney = (value) => {
      // Remueve caracteres no numéricos
      const numericValue = value.replace(/\D/g, '');
      // Agrega el separador de miles
      const formattedValue = numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
      setMoneyAmount(formattedValue);
    };


  return (
    <div className="exchange-modal-backdrop">
      <div className="exchange-modal">
        <button className="close-button-exchange-modal" onClick={onClose}>✖</button>
        <div className="exchange-modal-body">
          <div className="exchange-modal-content-left">
            <img src={card.cardImage} alt={card.cardName} className="exchange-modal-card-image" />
            
            {/* Bloque para mostrar las cartas que se ofrecen, movido debajo de la imagen */}
            <div className="exchange-modal-offer-summary">
              <h3>Cartas que ofreces ({offeredCards.length}):</h3>
              <div className="offered-cards-list"><ul>
                {offeredCards.map((offeredCard, index) => (
                  <div key={index}><li>{offeredCard.name} - {offeredCard.nombreEdicion}</li></div>
                ))}
                </ul>
            </div>
            <label>
                <input
                  type="checkbox"
                  checked={complementWithMoney}
                  onChange={(e) => setComplementWithMoney(e.target.checked)}
                /> Complementar con dinero
              </label>
              {complementWithMoney && (
                <input
                  type="text"
                  placeholder="$"
                  value={moneyAmount}
                  onChange={(e) => formatMoney(e.target.value)}
                />
              )}
          </div>
          </div>
          <div className="exchange-modal-content-right">
            <div className="exchange-modal-card-details">
              <img src={card.edicionLogo} alt="Logo de edición" className="exchange-modal-edition-logo" />
              <h2>{card.cardName}</h2>
              {/* Información del dueño de la carta */}
              <p><strong>Usuario:</strong> {card.userName}</p>
              <p><strong>Puntuación:</strong> {ownerRating}</p>
              <p><strong>Región:</strong> {card.regionName}</p>
              <p><strong>Método de intercambio:</strong> {card.metodoIntercambio}</p>
            </div>
            <div className="exchange-modal-wanted-cards">
            <input
            type="text"
            placeholder="Buscar carta..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="search-input"
          />
            <Swiper
              modules={[Navigation, Pagination, Scrollbar, Mousewheel]}
              spaceBetween={10}
              slidesPerView={'auto'}
              slidesPerGroup={5}
              //pagination={{ clickable: true }}
              navigation={{ enabled: true }}
              showsPagination={false}
              mousewheel={true}
              scrollbar={{ draggable: true }}
            >
              {/* Renderiza las cartas que coinciden con la clase highlight */}
              {filteredMatchingCards.map((card, index) => (
                <SwiperSlide key={index} className="card-highlight">
                <img src={card.image} alt={card.name} className="card-image" />
                <p className='nombre-carta-modal-ex'>{card.name}</p>
                <img src={card.logoEdicion} alt={card.nombreEdicion} className="edition-image" />
                {/* Aquí cambiamos la clase en función de si la carta está ofrecida o no */}
                <button
                  className={offeredCards.some(offeredCard => offeredCard.cartaId === card.cartaId) ? 'boton-quitar' : 'boton-ofrecer-modal'}
                  onClick={() => toggleCardInOffer(card)}
                >
                  {offeredCards.some(offeredCard => offeredCard.cartaId === card.cartaId) ? 'Quitar' : 'Ofrecer'}
                </button>
              </SwiperSlide>
              ))}
              {/* Renderiza las demás cartas del usuario logueado */}
              {filteredUserCards.filter(card => !matchingCards.some(matchingCard => matchingCard.cartaId === card.cartaId)).map((card, index) => (
                <SwiperSlide key={index}>
                <img src={card.image} alt={card.name} className="card-image" />
                <p className='nombre-carta-modal-ex'>{card.name}</p>
                <img src={card.logoEdicion} alt={card.nombreEdicion} className="edition-image" />
                {/* Aquí cambiamos la clase en función de si la carta está ofrecida o no */}
                <button
                  className={offeredCards.some(offeredCard => offeredCard.cartaId === card.cartaId) ? 'boton-quitar' : 'boton-ofrecer-modal'}
                  onClick={() => toggleCardInOffer(card)}
                >
                  {offeredCards.some(offeredCard => offeredCard.cartaId === card.cartaId) ? 'Quitar' : 'Ofrecer'}
                </button>
              </SwiperSlide>
              ))}
            </Swiper>
            </div>
            <div className="exchange-modal-actions">
              <button onClick={handleSendOffer} className="exchange-button">Enviar oferta</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExchangeModal;
