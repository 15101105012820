import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './SagaSelector.css';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SagaSelector = ({ onSagaSelect, selectedSaga }) => { // <-- Añade selectedSaga como prop y mantiene onSagaSelect
    const [sagas, setSagas] = useState([]);

    useEffect(() => {
        axios.get(`${API_BASE_URL}api/sagas`)
            .then(response => {
                setSagas(response.data);
            })
            .catch(error => {
                console.error("Error al obtener las sagas:", error);
            });
    }, []);

    return (
        <div className="saga-selector">
            {sagas.map(saga => (
                <div 
                    key={saga.id} 
                    className={`saga-item ${selectedSaga && selectedSaga.id === saga.id ? 'active-saga' : ''}`} 
                    onClick={() => {
                        console.log(`Seleccionada la saga: ${saga.nombre}`);
                        onSagaSelect(saga);
                    }}
                >
                    <img src={`${saga.ruta_logo}`} alt={saga.nombre} />
                </div>
            ))}
        </div>
    );
    
}

export default SagaSelector;

