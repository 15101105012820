import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'; // Importar el proveedor
import Login from './components/Login/Login';
import Home from './components/Home/Home';
import Register from './components/Register/Register';
import ProtectedRoute from './components/ProtectedRoutes';
import '@fortawesome/fontawesome-free/css/all.min.css';
import ResetPassword from './components/ResetPassword/ResetPassword';
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import 'react-toastify/dist/ReactToastify.css';
import Exchange from './components/Exchange/Exchange';

function App() {
    const isAuthenticated = !!localStorage.getItem('userToken');

    // Tu clave pública de sitio de reCAPTCHA aquí
    const reCaptchaKey = "6LdB8ewoAAAAAHjL1p0FMklWrW7u7ojV_QIZA6W_";

    return (
        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaKey}>
            <Router>
                <Routes>
                    <Route path="/" element={<Navigate to={isAuthenticated ? "/home" : "/login"} replace />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/home" element={<ProtectedRoute><Home /></ProtectedRoute>} />
                    <Route path="/exchange" element={<ProtectedRoute><Exchange /></ProtectedRoute>} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/reset-password" element={<ResetPassword />} />
                    <Route path="/forgot-password" element={<ForgotPassword />} />
                    {/* Puedes agregar más rutas aquí en el futuro */}
                </Routes>
            </Router>
        </GoogleReCaptchaProvider>
    );
}

export default App;
