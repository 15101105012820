import React, { useState, useEffect } from 'react';
import './Home.css';
import SagaSelector from '../SagaSelector/SagaSelector';
import EditionSlider from './EditionSlider/EditionSlider';
import CardGrid from './CardGrid/CardGrid';
import logo from '../../assets/images/logo.webp';
import axios from 'axios';
import SearchResultsTable, { handleSearch } from './SearchResultsTable/SearchResultsTable';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function Home() {
    const [isNewUser, setIsNewUser] = useState(false);
    //const [selectedSaga, setSelectedSaga] = useState({ id: 1, name: 'Pokemon' });
    const [selectedSaga, setSelectedSaga] = useState([]);
    const [editionCards, setEditionCards] = useState([]);
    const [userEditionCards, setUserEditionCards] = useState([]);
    const [currentFilter, setCurrentFilter] = useState('TODOS');
    const [selectedEdition, setSelectedEdition] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [hasSearched, setHasSearched] = useState(false);
    const [editionSearchTerm, setEditionSearchTerm] = useState('');
    const [isWizardOpen, setIsWizardOpen] = useState(false);
    const [wizardPage, setWizardPage] = useState(1);
    const [accountMenuOpen, setAccountMenuOpen] = useState(false);
    const [bannerUrl, setBannerUrl] = useState('');


    /* const [showGuide, setShowGuide] = useState(false);
    const [showEditionGuide, setShowEditionGuide] = useState(false); */

    const [highlightedArea, setHighlightedArea] = useState(null); // 'saga', 'edition', or null


    const handleEditionSelected = async (edition) => {
        setSelectedEdition(edition);
        setHighlightedArea('none');
        // Guardar la edición seleccionada en localStorage
        localStorage.setItem('lastSelectedEdition', JSON.stringify(edition));
        setHasSearched(false);  // <-- Ya añadiste esta línea anteriormente
        setSearchResults([]);  // <-- Añade esta línea para resetear los resultados de búsqueda
        try {
            const responseEditionCards = await axios.get(`${API_BASE_URL}api/cards?editionId=${edition.id}`);
            setEditionCards(responseEditionCards.data);
    
            const userId = localStorage.getItem('userId');
            const responseUserEditionCards = await axios.get(`${API_BASE_URL}api/usercards?editionId=${edition.id}&userId=${userId}`);
            setUserEditionCards(responseUserEditionCards.data);
        } catch (error) {
            console.error("Error al obtener las cartas:", error);
        }
    };

    const handleEditionSelectedForSaga = async (saga) => {
        try {
            const responseEditions = await axios.get(`${API_BASE_URL}api/editions?saga=${saga}`);
            const firstEdition = responseEditions.data[0];
            handleEditionSelected(firstEdition);
        } catch (error) {
            console.error("Error al obtener las ediciones:", error);
        }
    };

    const filterEditionCards = () => {
        if (!editionSearchTerm) return filteredCards;
        return filteredCards.filter(card => 
            card.nombre.toLowerCase().includes(editionSearchTerm.toLowerCase()) ||
            card.numeroCarta.toString().includes(editionSearchTerm)
        );
    };

    const handleLogout = () => {
        // Limpiar cualquier dato de sesión almacenado en localStorage
        localStorage.removeItem('userId');
        localStorage.removeItem('lastSelectedEdition');
        localStorage.removeItem('lastSelectedSaga');
        localStorage.removeItem('userToken');
        
        // Redirigir al usuario a la página de inicio de sesión
        window.location.href = '/login';
    };
    
    useEffect(() => {
        const fetchBannerUrl = async () => {
            if (selectedSaga && selectedSaga.id) {
                try {
                    const response = await axios.get(`${API_BASE_URL}api/saga_banners?sagaId=${selectedSaga.id}`);
                    setBannerUrl(response.data.banner_url);
                } catch (error) {
                    console.error("Error al obtener el banner:", error);
                }
            }
        };
    
        fetchBannerUrl();
    }, [selectedSaga]);

    useEffect(() => {
        const checkIfUserHasCards = async () => {
            const userId = localStorage.getItem('userId');
            try {
                const response = await axios.get(`${API_BASE_URL}api/hascards`, {
                    params: { userId }
                });
                if (!response.data.hasCards) {
                    setIsWizardOpen(true);
                    setIsNewUser(true); // <-- Añadir esta línea
                } else {
                    setIsNewUser(false); // <-- Añadir esta línea
                    // Si el usuario es antiguo, cargar la última edición seleccionada
                    const lastSelectedEdition = JSON.parse(localStorage.getItem('lastSelectedEdition'));
                    const lastSelectedSaga = JSON.parse(localStorage.getItem('lastSelectedSaga'));
                    if (lastSelectedEdition) {
                        handleEditionSelected(lastSelectedEdition);
                        setSelectedSaga(lastSelectedSaga);
                    }
                }
            } catch (error) {
                console.error("Error al verificar si el usuario tiene cartas:", error);
            }
        };
    
        checkIfUserHasCards();
    }, []);
    
    
    let filteredCards = editionCards;
    if (currentFilter === 'EN POSESIÓN') {
        filteredCards = editionCards.filter(card => 
            userEditionCards.some(userCard => userCard.id === card.id && !userCard.inWishlist)
        );
    } else if (currentFilter === 'FALTANTES') {
        filteredCards = editionCards.filter(card => !userEditionCards.some(userCard => userCard.id === card.id));
    }
    
    //CONSTANTE ANTIGUA PARA CALCULAR PORCENTAJE
    //const percentageOwned = userEditionCards.length / editionCards.length * 100;

    // Total de cartas que el usuario tiene de la edición (sin contar las de la lista de deseos)
    const userCardsNotInWishlist = userEditionCards.filter(userCard => !userCard.inWishlist).length;

    // Total de cartas de la edición
    const totalEditionCards = editionCards.length;

    // Porcentaje de cartas que el usuario tiene de la edición
    const percentageOwned = (userCardsNotInWishlist / totalEditionCards) * 100;
    
    return (
        <div className="home-container">
            <div className={`sagas-logos ${highlightedArea === 'saga' ? 'highlighted' : ''}`}>
            <SagaSelector 
                selectedSaga={selectedSaga}
                onSagaSelect={(saga) => {
                    setSelectedSaga(saga); 
                    setSelectedEdition(null); 
                    if (isNewUser) { // Solo resaltar el área de edición si es un nuevo usuario
                        setHighlightedArea('edition');
                    }
                    localStorage.setItem('lastSelectedSaga', JSON.stringify(saga));
                }}
            />
            </div>
            <header className="home-header">
                <a href='/Home'><img src={logo} alt="Logo" className="home-logo" /></a>
                <nav className="home-menu">
                    <a href="/Home" className="active">Colecciones</a>
                    <a href="/Exchange">Intercambios</a>
                    <a href="#">Market</a>
                </nav>
                <button className="account-button" onClick={() => setAccountMenuOpen(!accountMenuOpen)}>Mi cuenta</button>
                    {accountMenuOpen && (
                        <div className="account-menu-dropdown">
                            <button onClick={handleLogout}>Cerrar sesión</button>
                        </div>
                    )}
            </header>
            {bannerUrl && (
                <div className="main-banner">
                    <img src={bannerUrl} alt="Banner principal" />
                    {selectedSaga && selectedSaga.id && ( // Asegúrate de que selectedSaga tenga un id válido antes de mostrar el buscador
                <div className="search-container-home">
                    <input className='search-container-home-input'
                        type="text" 
                        placeholder="Buscar carta..." 
                        value={searchTerm} 
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <button className='search-container-home-button' onClick={() => {handleSearch(selectedSaga, searchTerm, setSearchResults);
                    setHasSearched(true);
                    }}>Buscar</button>
                </div>
            )}
                </div>
            )}
            <div className="home-body">
            <div className={`sets-slider ${highlightedArea === 'edition' ? 'highlighted' : ''}`}>
                    {selectedSaga && <EditionSlider selectedSaga={selectedSaga} onEditionSelected={handleEditionSelected} />}
                </div>
    
                <div className="cards-block">
                    {selectedEdition && (
                        <div className="edition-title">
                            <img src={selectedEdition ? selectedEdition.logo : ''} alt="Logo de la edición" className="edition-logo" />
                            {selectedEdition.nombre} - {percentageOwned.toFixed(0)}% ({userCardsNotInWishlist}/{editionCards.length})
                            <input 
                                type="text" 
                                placeholder="Buscar en edición..." 
                                value={editionSearchTerm} 
                                onChange={(e) => setEditionSearchTerm(e.target.value)}
                                className="edition-search-input"
                            />
                        </div>
                    )}
                    {selectedSaga && !hasSearched &&(
                        <div className="filter-buttons">
                            <button onClick={() => setCurrentFilter('TODOS')} className={currentFilter === 'TODOS' ? 'active' : ''}>TODOS</button>
                            <button onClick={() => setCurrentFilter('EN POSESIÓN')} className={currentFilter === 'EN POSESIÓN' ? 'active' : ''}>EN POSESIÓN</button>
                            <button onClick={() => setCurrentFilter('FALTANTES')} className={currentFilter === 'FALTANTES' ? 'active' : ''}>FALTANTES</button>
                        </div>
                    )}
                    {!hasSearched && <CardGrid cards={filterEditionCards()} userCards={userEditionCards} setUserEditionCards={setUserEditionCards} />}
                    <div className="cards-list">
                        {/* Aquí debes agregar las imágenes de las cartas */}
                    </div>
                    <SearchResultsTable 
                        selectedSaga={selectedSaga} 
                        searchTerm={searchTerm} 
                        setSearchResults={setSearchResults} 
                        searchResults={searchResults} 
                    />
                </div>
            </div>
    
            {isWizardOpen && (
                <div className="wizard-modal">
                    {wizardPage === 1 && (
                        <div>
                            <h2 className='titulo-wiz-1'>¡Bienvenido a Tradeame!</h2>
                            <p>¿Gracias por unirte! Aquí podrás seguir día a día como tu colección TCG va cambiando.</p> 
                            <p>Agrega cartas a tu colección, busca e intercambia cartas de las sagas más conocidas.</p>
                            <p>Es importante que sepas que estamos en BETA, por lo que demoras en carga o errores podrían suceder. Tampoco está optimizado para vista móvil aún.</p>
                            <p>Como es tu primera vez aquí, te enseñaremos rápidamente cómo funciona todo.</p>
                            <button className="wizard-button-1" onClick={() => setWizardPage(2)}>Siguiente</button>
                        </div>
                    )}
                    {wizardPage === 2 && (
                        <div>
                            <h2 className='titulo-wiz-2'>Funcionalidades de los botones</h2>
                            <p>Cada carta tiene botones para poder generar diferentes acciones. </p>
                            <p><i className="fas fa-plus"></i>: Agrega la carta a la colección. Con ello, tu carta aparecerá como obtenida.</p>
                            <p><i className="fas fa-times"></i> : Elimina la carta de la colección.</p>
                            <p><i className="fas fa-arrows-rotate"></i> : Marca la carta para intercambio. Esto permite que otras personas sepan que tienes esta carta disponible para intercambio, y te puedan ofrecer otra(s) carta(s) por ella.</p>
                            <p><i className="fa-brands fa-searchengin"></i> : Marca esta carta como que la buscas para tu colección, lo que permite que otros usuarios sepan que la necesitas y puedan ofrecertela en intercambio. Esto solo es posible en cartas que NO tengas en tu colección.</p>
                            <button className="wizard-button-1" onClick={() => setWizardPage(3)}>Siguiente</button>
                        </div>
                    )}
                    {wizardPage === 3 && (
                        <div>
                            <h2>Selecciona un set y una edición</h2>
                            <p>Para comenzar, selecciona un set y una edición para agregar tu primera carta a la colección.</p>
                            <button className="wizard-button-1" onClick={() => {setIsWizardOpen(false); setHighlightedArea('saga'); }}>Finalizar</button>
                        </div>
                    )}
                </div>
            )}
            {isNewUser && (highlightedArea === 'saga' || highlightedArea === 'edition') && ( 
                <div className="overlay">
                    <p>{highlightedArea === 'saga' ? 'Primero, selecciona una saga' : 'Ahora, selecciona una edición'}</p>
                </div>
            )}
        </div>
    );
    
    
}

export default Home;
