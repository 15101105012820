import React, { useState } from 'react';
import './ResetPassword.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

function ResetPassword() {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleResetPassword = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${API_BASE_URL}api/resetPassword`, {
                email: email,
                code: verificationCode,
                newPassword: newPassword
            });
            
            if (response.data.message) {
                alert(response.data.message);
                navigate('/login');
            } else {
                setErrorMessage('Error al restablecer la contraseña. Por favor, intenta nuevamente.');
            }
        } catch (error) {
            setErrorMessage('Error al restablecer la contraseña.');
        }
    };

    return (
        <div className="reset-password-container">
            <h2 className="reset-password-title">Restablecer Contraseña</h2>
            <form className="reset-password-form" onSubmit={handleResetPassword}>
                <label htmlFor="email">Ingresa tu e-mail:</label>
                <input type="email" id="email" name="email" required 
                       value={email} 
                       onChange={(e) => setEmail(e.target.value)} />
                
                <label htmlFor="code">Ingresa el código de verificación:</label>
                <input type="text" id="code" name="code" required 
                       value={verificationCode} 
                       onChange={(e) => setVerificationCode(e.target.value)} />

                <label htmlFor="newPassword">Ingresa tu nueva contraseña:</label>
                <input type="password" id="newPassword" name="newPassword" required 
                       value={newPassword} 
                       onChange={(e) => setNewPassword(e.target.value)} />
                
                <button type="submit">Restablecer Contraseña</button>
                <p className="error-message">{errorMessage}</p>
            </form>
        </div>
    );
}

export default ResetPassword;
