import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ViewCard.css';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const ViewCard = ({ cardId, onClose, cards, userCards, setUserEditionCards, setWishlistCards, showButtons }) => {
    const [cardDetails, setCardDetails] = useState(null);
    const [cardStatus, setCardStatus] = useState(null);


    const handleAddToCollection = async (cardId) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.post(`${API_BASE_URL}api/addcardtocollection`, {
                userId,
                cardId
            });
            const addedCard = cards.find(card => card.id === cardId);
            setUserEditionCards(prevUserCards => [...prevUserCards, addedCard]);

            const cardName = addedCard.nombre;
            const cardNumber = addedCard.numeroCarta;
            toast.success(`Se ha agregado la carta ${cardNumber} - ${cardName} a tu colección.`, {
                position: "bottom-center",
                autoClose:1500,
                theme: "dark"
            });

        } catch (error) {
            console.error("Error al agregar la carta:", error);
        }
    };

    const handleRemoveFromCollection = async (cardId) => {
        const userId = localStorage.getItem('userId');
        try {
            const response = await axios.delete(`${API_BASE_URL}api/removecardfromcollection`, {
                data: { userId, cardId }
            });
            setUserEditionCards(prevUserCards => prevUserCards.filter(card => card.id !== cardId));

            toast.success(`Se ha eliminado la carta de tu colección.`, {
                position: "bottom-center",
                theme: "dark",
                autoClose:1500
            });

        } catch (error) {
            console.error("Error al eliminar la carta:", error);
        }
    };

    const handleToggleTrade = async (cardId, currentTradeStatus) => {
        const userId = localStorage.getItem('userId');
        const newTradeStatus = currentTradeStatus === 1 ? 0 : 1;
        try {
            const response = await axios.put(`${API_BASE_URL}api/toggletradestatus`, {
                userId,
                cardId,
                paraIntercambio: newTradeStatus
            });

            const updatedUserCards = userCards.map(userCard => 
                userCard.id === cardId ? { ...userCard, paraIntercambio: newTradeStatus } : userCard
            );
            setUserEditionCards(updatedUserCards);

            const card = cards.find(c => c.id === cardId);
            const cardName = card.nombre;
            const cardNumber = card.numeroCarta;

            if (newTradeStatus === 1) {
                toast.success(`Se ha marcado la carta ${cardNumber} - ${cardName} para intercambio.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 1500
                });
            } else {
                toast.success(`Se ha sacado la carta ${cardNumber} - ${cardName} del intercambio.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 1500
                });
            }

        } catch (error) {
            console.error("Error al actualizar el estado de intercambio:", error);
        }
    };

    const handleToggleWishlist = async (cardId) => {
        const userId = localStorage.getItem('userId');
        const userCard = userCards.find(uc => uc.id === cardId);
        const isOnWishlist = userCard && userCard.inWishlist;

        try {
            let response;
            const card = cards.find(c => c.id === cardId);
            const cardName = card.nombre;
            const cardNumber = card.numeroCarta;

            if (isOnWishlist) {
                response = await axios.delete(`${API_BASE_URL}api/removecardfromwishlist`, {
                    data: { userId, cardId }
                });
                setWishlistCards(prevWishlist => prevWishlist.filter(id => id !== cardId));

                setUserEditionCards(prevUserCards => prevUserCards.map(userCard => 
                    userCard.id === cardId ? { ...userCard, inWishlist: false } : userCard
                ));

                if (!userCard.enColeccion) {
                    setUserEditionCards(prevUserCards => prevUserCards.filter(uc => uc.id !== cardId));
                }

                toast.success(`Se ha eliminado la carta ${cardNumber} - ${cardName} de tu lista de buscados.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 2000
                });

            } else {
                response = await axios.post(`${API_BASE_URL}api/addcardtowishlist`, {
                    userId,
                    cardId
                });
                setWishlistCards(prevWishlist => [...prevWishlist, cardId]);

                const addedWishlistCard = cards.find(card => card.id === cardId);
                setUserEditionCards(prevUserCards => [...prevUserCards, { ...addedWishlistCard, inWishlist: true }]);

                toast.success(`Se ha marcado la carta ${cardNumber} - ${cardName} como buscado.`, {
                    position: "bottom-center",
                    theme: "dark",
                    autoClose: 2000
                });
            }

        } catch (error) {
            console.error("Error al actualizar la lista de deseos:", error);
        }
    };


    useEffect(() => {
        const fetchCardDetails = async () => {
            try {
                const response = await axios.get(`${API_BASE_URL}api/carddetails`, {
                    params: { cardId }
                });
                setCardDetails(response.data);
            } catch (error) {
                console.error("Error al obtener los detalles de la carta:", error);
            }
        };

        const fetchCardStatus = async () => {
            const userId = localStorage.getItem('userId');
            try {
                const response = await axios.get(`${API_BASE_URL}api/cardstatus`, {
                    params: { userId, cardId }
                });
                // Aquí puedes guardar el estado de la carta en un nuevo estado local
                // Por ejemplo: setCardStatus(response.data);
                setCardStatus(response.data);
            } catch (error) {
                console.error("Error al obtener el estado de la carta:", error);
            }
        };
        fetchCardStatus();
        fetchCardDetails();
    }, [cardId]);

    // Las funciones para manejar los eventos de los botones (similar a lo que proporcioné anteriormente)
    // ... (insertar las funciones handleAddToCollection, handleRemoveFromCollection, handleToggleTrade, handleToggleWishlist aquí)

    if (!cardDetails) return null;

    //const userCard = userCards.find(userCard => userCard.id === cardId);
    const userCard = (userCards || []).find(userCard => userCard.id === cardId);
    const userHasCard = !!userCard && !userCard.inWishlist;
    const isOnWishlist = !!userCard && userCard.inWishlist;
    const isForTrade = userHasCard && userCard.paraIntercambio === 1;

    return (
        <div className="modal">

            <div className="modal-content">
                <span className="close-btn" onClick={onClose}>&times;</span>
                <img className='img-carta-modal' src={cardDetails.imagen} alt={cardDetails.nombre} />
                <div className="card-details-container">
                    <div className="card-info">
                        <h2>{cardDetails.nombre}</h2>
                        <p>Número: {cardDetails.numeroCarta}</p>
                        <p>Edición: {cardDetails.edicion}</p>
                        <p>Categoría: {cardDetails.categoria}</p>
                        <p>Rareza: {cardDetails.tipo}</p>
                    </div>
                    <div className="viewcard-actions">
                        {showButtons !== false && userHasCard ? 
                            <button className="viewcard-remove-btn" title='Quitar de mi colección' onClick={() => handleRemoveFromCollection(cardId)}><i className="fas fa-times"></i></button> :
                            showButtons !== false && <button className="viewcard-add-btn" title='Agregar a mi colección' onClick={() => handleAddToCollection(cardId)}><i className="fas fa-plus"></i></button>
                        }
                        {showButtons !== false && userHasCard && 
                            <button 
                                className={`viewcard-trade-btn ${isForTrade ? 'active-trade' : ''}`}
                                onClick={() => handleToggleTrade(cardId, isForTrade ? 1 : 0)}
                                title={isForTrade ? "Quitar de intercambio" : "Agregar a intercambio"}
                            >
                                <i className={`fas fa-${isForTrade ? 'rotate-left': 'arrows-rotate'}`}></i>
                            </button>
                        }
                        {showButtons !== false && !userHasCard && 
                            <button 
                                className="viewcard-search-btn"
                                onClick={() => handleToggleWishlist(cardId)}
                                title={isOnWishlist ? "Dejar de buscar":"Buscar carta"}
                            >
                                <i className={`fa-${isOnWishlist ? 'regular fa-hand' : 'brands fa-searchengin'}`}></i>
                            </button>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ViewCard;
